<template>
  <v-container class="general">
    <PageTitle :title="'Venues'" />
    <v-layout row wrap>
      <v-flex xs12>
        <v-text-field
          v-model="venue.name"
          v-validate.disable="'required'"
          :data-vv-name="'name'"
          :data-vv-as="'Name'"
          :label="'Name'"
          hide-details
        ></v-text-field>
        <ErrorMessages :error-messages="errors.collect('name')" />
      </v-flex>
      <v-flex xs12>
        <v-textarea
          v-model="venue.description"
          :label="'Description'"
        ></v-textarea>
      </v-flex>
      <v-flex
        xs12
        class="mb-5 address-input"
        :class="{ 'has-value': venue.address || !venueInput }"
      >
        <v-combobox
          v-model="venue.address"
          :data-vv-name="'address'"
          :data-vv-as="'Address'"
          item-value="description"
          :items="predictions"
          :search-input.sync="venueInput"
          :label="`Address`"
          no-filter
          hide-details
        >
          <template slot="selection" slot-scope="data">
            <div>{{ data.item.description }}</div>
          </template>
          <template slot="item" slot-scope="data">
            <div class="slot-item">{{ data.item.description }}</div>
          </template>
          <template slot="append-item">
            <div class="pa-3">
              <v-img
                :src="require(`@/assets/images/powered_by_google_on_white.png`)"
                width="144"
                class="ml-auto"
              ></v-img>
            </div>
          </template>
        </v-combobox>
        <ErrorMessages :error-messages="errors.collect('address')" />
      </v-flex>
      <v-flex xs12>
        <v-btn round class="ml-0 theme-primary text-none" @click="goToVenueList"
          >Cancel</v-btn
        >
        <v-btn
          v-if="!venueId"
          round
          class="ml-0 theme-primary-bg theme-on-primary text-none"
          :loading="isLoading"
          @click="createGroupVenue"
          >Create</v-btn
        >
        <v-btn
          v-if="venueId"
          round
          class="ml-0 theme-primary-bg theme-on-primary text-none"
          :loading="isLoading"
          @click="updateGroupVenue"
          >Update</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    autocompleteService: null,
    venue: {},
    predictions: [],
    venueInput: "",
  }),
  computed: {
    venueId() {
      return this.$route.params.venue_id;
    },
  },
  mounted() {
    this.initAutocomplete();
  },
  methods: {
    goToVenueList() {
      this.$router.push({ name: "venues" }).catch(() => {});
    },
    initAutocomplete() {
      this.autocompleteService = new google.maps.places.AutocompleteService();
    },
    setPredictions(predictions, status) {
      if (status != google.maps.places.PlacesServiceStatus.OK) {
        return;
      }

      this.predictions = predictions;
    },
    listPredictions() {
      if (!this.venueInput) {
        this.predictions = [];
        return;
      }

      this.autocompleteService.getQueryPredictions(
        { input: this.venueInput },
        this.setPredictions,
      );
    },
    async getGeocode(placeId) {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ placeId: placeId }, (results, status) => {
          if (status === "OK") {
            resolve(results[0]);
          } else {
            reject(status);
          }
        });
      });
    },
    async createGroupVenue() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      this.isLoading = true;

      if (this.venue.address && !this.venue.address.place_id) {
        this.isLoading = false;
        return;
      }

      try {
        let addressData = {};

        if (this.venue.address) {
          const geocode = await this.getGeocode(this.venue.address.place_id);

          if (!geocode || !geocode.place_id) {
            this.isLoading = false;
            return;
          }

          addressData = {
            address: this.venue.address.description,
            lat: geocode.geometry.location.lat(),
            lng: geocode.geometry.location.lng(),
            place_id: geocode.place_id,
          };
        }

        const groupVenuesCreateSpecs = {
          name: this.venue.name,
          description: this.venue.description,
          ...addressData,
        };

        this.$api.groupVenues.create(
          groupVenuesCreateSpecs,
        ).then((res) => {
          
          if(!res) return;
          
          this.isLoading = false;
          this.$store.dispatch("addNotification", {
            message: `Venues \"${this.venue.name}\" created`,
          });
          this.goToVenueList();
        })
        .catch((error) => this.errorMessageShow(error));

      } catch (error) {
        this.isLoading = false;
      }
    },
    async getGroupVenue(id) {
      if (!id) return;

      this.isLoading = true;

      try {
        const groupVenuesGetResponse = await this.$api.groupVenues.get(id);

        this.isLoading = false;

        if (!groupVenuesGetResponse.data) {
          this.isLoading = false;
          return;
        }

        this.venue = {
          id: groupVenuesGetResponse.data.data.id,
          name: groupVenuesGetResponse.data.data.name,
          description: groupVenuesGetResponse.data.data.description,
          address: {
            description: groupVenuesGetResponse.data.data.address,
          },
          lat: groupVenuesGetResponse.data.data.lat,
          lng: groupVenuesGetResponse.data.data.lng,
          place_id: groupVenuesGetResponse.data.data.place_id,
        };
      } catch (error) {
        this.isLoading = false;
      }
    },
    async updateGroupVenue() {
      const isValid = await this.$validator.validate();

      if (!isValid) return;

      this.isLoading = true;

      try {
        let geocodeParams = {};

        if (this.venue.address && this.venue.address.place_id) {
          const geocode = await this.getGeocode(this.venue.address.place_id);

          if (!geocode || !geocode.place_id) {
            this.isLoading = false;
            return;
          }

          geocodeParams = {
            lat: geocode.geometry.location.lat(),
            lng: geocode.geometry.location.lng(),
            place_id: geocode.place_id,
          };
        } else {
          geocodeParams = {
            lat: this.venue.lat,
            lng: this.venue.lng,
            place_id: this.venue.place_id,
          };
        }

        const groupVenuesUpadateSpecs = [
          this.venue.id,
          {
            name: this.venue.name,
            description: this.venue.description,
            address: this.venue.address.description,
            ...geocodeParams,
          },
        ];

        this.$api.groupVenues.update(
          ...groupVenuesUpadateSpecs,
        ).then((res) => {
          
          if(!res) return;
          
          this.isLoading = false;
          this.$store.dispatch("addNotification", {
            message: `Venues \"${this.venue.name}\" updated`,
          });
          this.goToVenueList();
        })
        .catch((error) => this.errorMessageShow(error));

      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  watch: {
    venueId: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.getGroupVenue(val);
      },
    },
    "venue.address"(val) {
      if (typeof val === "string" || val instanceof String) {
        this.venue.address = null;
      }
    },
    venueInput() {
      this.listPredictions();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .address-input {
  .v-input__icon--append {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.has-value .v-input__icon--append {
    opacity: 0;
  }
}
</style>
